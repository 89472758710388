import {
  grey50,
  highlightColor,
  invertedColor,
  linkColor,
  linkHoverColor,
  mutedColor,
} from '@jaxsta/theme/colors';
import { regularWeight } from '@jaxsta/theme/typography';
import styled, { css } from 'styled-components';

type HyperLinkStyleProps = {
  inverted?: boolean;
  muted?: boolean;
  subtle?: boolean;
  highlight?: boolean;
  undecorated?: boolean;
  showDecoration?: boolean;
  disabled?: boolean;
};

export const hyperLinkStyle = css<HyperLinkStyleProps>`
  color: ${linkColor};
  cursor: pointer;
  text-decoration: none;
  font-weight: ${regularWeight};
  transition: all 280ms;

  ${(props) =>
    props.showDecoration &&
    css`
      border-bottom: 1px solid ${linkColor};
    `};

  &:hover {
    color: ${linkHoverColor};
  }

  ${(props) =>
    props.inverted &&
    css`
      color: ${invertedColor};
    `};

  ${(props) =>
    props.muted &&
    css`
      color: ${mutedColor};

      &:hover {
        color: white;
      }
    `};

  ${(props) =>
    props.subtle &&
    css`
      color: ${grey50};

      &:hover {
        color: ${mutedColor};
      }
    `};

  ${(props) =>
    props.highlight &&
    css`
      color: ${highlightColor};

      &:hover {
        color: ${linkHoverColor};
      }
    `};

  &:hover,
  &:focus {
    outline: none;
    border-bottom: 1px solid ${linkHoverColor};
  }

  ${(props) =>
    props.undecorated &&
    css`
      border-bottom: none;

      &:hover,
      &:focus {
        border-bottom: 0;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: none;
      pointer-events: none;
      color: black;
    `};
`;

export const HyperLink = styled.a`
  ${hyperLinkStyle};
`;
