import { JAXSTA_ONE_SHEET_EMBED_CLASS } from '@jaxsta/global-constants/embeds';
import { PanelIds } from '@jaxsta/one-sheet';
import ContentHeightFrame from '@jaxsta/ui/frame/content-height-frame';
import React from 'react';
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import Widget from './widget';

(function start({ document: doc }: Window) {
  function checkIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  const isWithinIframe = checkIframe();

  const widgetDivs = doc.querySelectorAll(`.${JAXSTA_ONE_SHEET_EMBED_CLASS}`);

  if (!widgetDivs || !widgetDivs.length) {
    throw new Error(
      'There are no divs on the page where the Jaxsta One-Sheet widget can load.',
    );
  }

  widgetDivs.forEach((div, index) => {
    const oneSheetUrl = div.getAttribute('data-one-sheet');

    if (!oneSheetUrl) {
      throw new Error('The property data-one-sheet was not set.');
    }

    const match =
      /^https?:\/\/(www|dev|stage).jaxsta.(com|io)\/one-sheet\/([^/\s]+)\s*$/gm.exec(
        oneSheetUrl.trim().toLowerCase(),
      );

    if (!match) {
      throw new Error('The value of data-one-sheet is invalid.');
    }

    const artist = match[3];

    const panels = div.getAttribute('data-panels');

    if (!panels) {
      throw new Error(
        `The property data-panels was not set for ${oneSheetUrl}.`,
      );
    }

    const selectedPanels = panels
      .split(',')
      .map((o) => o.toLowerCase().trim()) as PanelIds[];

    function withIframe(widget: React.ReactNode) {
      if (isWithinIframe) {
        return widget;
      }

      return <ContentHeightFrame>{widget}</ContentHeightFrame>;
    }

    ReactDOM.render(
      <React.StrictMode key={index}>
        {withIframe(
          <Widget artist={artist} panels={selectedPanels as PanelIds[]} />,
        )}
      </React.StrictMode>,
      div,
    );
  });
})(window);
