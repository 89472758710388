import qs from 'qs';
import { getApiHost } from './helpers/host';
import {
  ApiGetRequestObject,
  APINamespaces,
  ApiPostRequestObject,
} from './types/api';
import { ApiError } from './types/error';

async function handleResponse(response: Response) {
  if (!response.ok) {
    throw new ApiError(
      'An error occurred while fetching the data.',
      await response.json(),
      response.status,
    );
  }

  return response.json();
}

export default abstract class JaxstaAPI {
  constructor(private namespace: APINamespaces) {}

  private getAPIUrl<TSearch = unknown>(req: ApiGetRequestObject<TSearch>) {
    const search = req.search
      ? qs.stringify(req.search, { addQueryPrefix: true })
      : '';
    return `${getApiHost()}/${this.namespace}/${req.path}${search}`;
  }

  public get<TResult, TSearch = unknown>(
    req: ApiGetRequestObject<TSearch>,
  ): Promise<TResult> {
    return fetch(this.getAPIUrl(req)).then(handleResponse);
  }

  public post<TResult, TData, TSearch = unknown>(
    req: ApiPostRequestObject<TData, TSearch>,
  ): Promise<TResult> {
    return fetch(this.getAPIUrl(req), {
      method: 'POST',
      body: JSON.stringify(req.data),
    }).then(handleResponse);
  }

  public patch<TResult, TData, TSearch = unknown>(
    req: ApiPostRequestObject<TData, TSearch>,
  ): Promise<TResult> {
    const headers = req.sessionToken
      ? {
          Authorization: `Bearer ${req.sessionToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      : undefined;
    return fetch(this.getAPIUrl(req), {
      method: 'PATCH',
      body: JSON.stringify(req.data),
      headers,
    }).then(handleResponse);
  }
}
