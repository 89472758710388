import { gold, grey50 } from '@jaxsta/theme/colors';
import { gutter, gutterDesktop } from '@jaxsta/theme/layout';
import { regularWeight } from '@jaxsta/theme/typography';
import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '../assets/font-awesome';
import { sectionContainerStyle } from '../grid/grid';
import { media } from '../responsive/media-query';
import { h2Style, h3Style, h4Style, h5Style } from '../typography';

type ErrorMessageProps = {
  message: string;
  tagline?: string;
  className?: string;
};

const ErrorMessage: FunctionComponent<ErrorMessageProps> = ({
  message,
  tagline,
  className,
}) => (
  <SectionContainer className={className}>
    <InnerContainer>
      <IconContainer>
        <FontAwesomeIcon icon="exclamation-triangle" />
      </IconContainer>
      <Container>
        <Title>Sorry... 😔</Title>
        <SubHeading>
          <div>{message}</div>
          <Dull>{tagline}</Dull>
        </SubHeading>
      </Container>
    </InnerContainer>
  </SectionContainer>
);

const SectionContainer = styled.div`
  ${sectionContainerStyle};
  padding: ${gutterDesktop};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media(
    'desktop',
    css`
      align-items: flex-start;
      flex-direction: row;
    `,
  )};
`;

export const IconContainer = styled.div`
  flex-shrink: 0;
  margin-right: 12px;
  display: none;

  ${media(
    'desktop',
    css`
      display: initial;
    `,
  )}

  > svg {
    margin-top: ${gutter};
    width: 50px;
    height: 50px;
    color: ${gold};
  }
`;

const Container = styled.div`
  flex: 1;
`;

export const Title = styled.div`
  ${h3Style};
  ${media('desktop', h2Style())};
`;

export const SubHeading = styled.div`
  ${h5Style};
  ${media('desktop', h4Style())};
  font-weight: ${regularWeight};
`;

const Dull = styled.div`
  color: ${grey50};
`;

export default ErrorMessage;
