import useSwr from 'swr';
import { getOneSheet } from './one-sheet-api';

export function useOneSheet(artist: string | null) {
  const { data } = useSwr(artist, getOneSheet, {
    suspense: true,
  });

  return data;
}
