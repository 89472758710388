import { css } from 'styled-components';

export const brownPro = css`
  @font-face {
    font-family: 'Brown-Pro';
    src: url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-light.eot');
    src: url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-light.eot?#iefix')
        format('embedded-opentype'),
      url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-light.woff2')
        format('woff2'),
      url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-light.woff')
        format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Brown-Pro';
    src: url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-regular.eot');
    src: url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-regular.eot?#iefix')
        format('embedded-opentype'),
      url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-regular.woff2')
        format('woff2'),
      url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-regular.woff')
        format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: 'Brown-Pro';
    src: url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-bold.eot');
    src: url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-bold.eot?#iefix')
        format('embedded-opentype'),
      url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-bold.woff2')
        format('woff2'),
      url('https://artwork.jaxsta.com/assets/fonts/lineto-brown-pro-bold.woff')
        format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: block;
  }
`;
