import { setAlpha } from '@jaxsta/helpers/color';

/* PALETTE
================================================================== */

/* BRANDING */
export const primary = 'hsla(170, 70%, 35%, 1)';
export const primaryLight = 'hsla(170, 70%, 45%, 1)';
export const primaryDark = 'hsla(170, 70%, 25%, 1)';

/* Role Groups */
export const maroon = 'hsla(0,   70%, 30%, 1)';
export const olive = 'hsla(70,  50%, 55%, 1)';
export const watermelon = 'hsla(350, 55%, 60%, 1)';
export const powderBlue = 'hsla(175, 35%, 65%, 1)';
export const salmon = 'hsla(10,  65%, 75%, 1)';
export const airForceBlue = 'hsla(195, 25%, 45%, 1)';
export const tangerine = 'hsla(25,  90%, 60%, 1)';
export const tan = 'hsla(40,  45%, 70%, 1)';
export const turquoise = 'hsla(170, 40%, 55%, 1)';
export const fossil = 'hsla(70,  5%,  55%, 1)';
export const royalBlue = 'hsla(255, 60%, 20%, 1)';
export const skyBlue = 'hsla(185, 45%, 50%, 1)';
export const magneta = 'hsla(340, 55%, 45%, 1)';
export const canary = 'hsla(45,  85%, 55%, 1)';
export const fire = 'hsla(10,  75%, 50%, 1)';
export const burgundy = 'hsla(0, 100%, 25.1%, 1)';

/* Article tags */
export const gold = 'hsla(46, 65%, 52%, 1)';

/* Tile banners */
export const tileGrey = 'hsl(165, 3%, 30%)';
export const tileBlue = 'hsl(206, 41%, 50%)';
export const tileMaroon = 'hsl(340, 55%, 45%)';
export const tileOrange = 'hsl(10, 64%, 60%)';

/* Brands */
export const brandSpotify = '#00d95b';

/* GREYSCALE */
export const white = 'hsla(0, 0%, 100%, 1)';
export const black = 'hsla(0, 0%, 0%, 1)';

export const grey10 = 'hsla(0, 0%, 10%, 1)';
export const grey15 = 'hsla(0, 0%, 15%, 1)';
export const grey20 = 'hsla(0, 0%, 20%, 1)';
export const grey50 = 'hsla(0, 0%, 50%, 1)';
export const grey70 = 'hsla(0, 0%, 70%, 1)';
export const grey80 = 'hsla(0, 0%, 80%, 1)';
export const grey85 = 'hsla(0, 0%, 85%, 1)';
export const grey90 = 'hsla(0, 0%, 90%, 1)';
export const grey95 = 'hsla(0, 0%, 95%, 1)';
export const grey98 = 'hsla(0, 0%, 98%, 1)';

/* Alerts */
export const red = 'hsla(0, 60%, 50%, 1)';
export const lightGreen = 'hsla(169, 57%, 95%, 1)';

/* Backgrounds */
export const brown = '#2C2220;';
export const darkBrown = '#070509;';
export const darkerBrown = '#404040;';
export const darkestBrown = '#171717;';

/* States */
export const selectedGreen = '#E6F4F2';

/* VARIABLES
================================================================== */

export const grey90Transparent = setAlpha(grey90, 0);
export const grey95Transparent = setAlpha(grey95, 0);
export const semiTransparent50Grey = setAlpha(grey50, 0.5);
export const whiteTransparent = setAlpha(white, 0);
export const semiTransparent20white = setAlpha(white, 0.2);
export const semiTransparent50white = setAlpha(white, 0.5);
export const semiTransparent80white = setAlpha(white, 0.8);
export const semiTransparent80Grey = setAlpha(grey80, 0.8);
export const blackTransparent = setAlpha(black, 0.3);
export const black10Transparent = setAlpha(black, 0.05);
export const black50Transparent = setAlpha(black, 0.5);
export const black20Transparent = setAlpha(black, 0.2);
export const primarySemiTransparent = setAlpha(primary, 0.8);
export const redSemiTransparent = setAlpha(red, 0.8);
export const airForceBlueTransparent = setAlpha(airForceBlue, 0.2);

export const baseColor = grey10;
export const invertedColor = white;
export const highlightColor = primary;
export const highlightColorHover = primarySemiTransparent;
export const mutedColor = grey50;

export const linkColor = black;
export const linkHoverColor = primaryLight;
