export const defaultFontFamily = '"Brown-Pro", Arial, Helvetica, sans-serif';
export const baseSize = '12px';
export const baseLineHeight = '1.4'; /* to inherit from local text height */

export const superLightWeight = '400';
export const lightWeight = '500';
export const regularWeight = '700';
export const boldWeight = '900';

export const fontSize1 = '42px';
export const fontSize2 = '28px';
export const fontSize3 = '26px';
export const fontSize4 = '21.6px';
export const fontSize5 = '18px';
export const fontSize6 = '16px';
export const fontSize7 = '14.4px';
export const fontSize8 = '12px';
export const fontSize9 = '10px';

export const lineHeight8 = '18px';

// export const boldLetterSpacing =
//   '-0.021rem'; /*Inline dynamic bolding visual bugfix*/
export const boldLetterSpacing = '-0.001rem';
