import type { PanelIds } from '@jaxsta/one-sheet';
import WidgetFrame from '@jaxsta/one-sheet/components/embed/widget-frame';
import WidgetSuspense from '@jaxsta/one-sheet/components/embed/widget-suspense';
import { brownPro } from '@jaxsta/theme/fonts';
import { globalStyles } from '@jaxsta/theme/global.styles';
import { initializeFontAwesome } from '@jaxsta/ui/assets/font-awesome';
import ResponsiveContextProvider from '@jaxsta/ui/responsive/responsive-context';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

initializeFontAwesome();

type WidgetProps = {
  artist: string | null;
  panels: PanelIds[];
};

const Widget: FunctionComponent<WidgetProps> = (props) => {
  return (
    <ResponsiveContextProvider<HTMLDivElement>>
      {(ref) => (
        <WidgetFrameEmbedded innerRef={ref}>
          <WidgetSuspense {...props} />
        </WidgetFrameEmbedded>
      )}
    </ResponsiveContextProvider>
  );
};

// I know this visiblity: visible is a bit redundant, but
// for some reason the build fails if I only have the ${}s
// in this template.
// We should investigate further why that's happening.
const WidgetFrameEmbedded = styled(WidgetFrame)`
  ${brownPro};
  ${globalStyles};

  visibility: visible;
`;

export default Widget;
