export function getApiHost() {
  return API_HOST;
}

export function getWebsiteHost() {
  return WEBSITE_HOST;
}

export function getEmbedHost(widget: string) {
  return EMBED_HOST.replace('{widget}', widget);
}
