import {
  black10Transparent,
  black20Transparent,
  blackTransparent,
  grey95,
} from './colors';

export const shadowColorLight = grey95;
export const shadowColorDark = black20Transparent;
export const shadowColorBlack = blackTransparent;
export const boxShadow = black10Transparent;
export const borderRadiusSm = '3px';
export const borderRadiusMd = '4px';
export const borderRadiusLg = '5px';

export const shadowLight = `0 2px 8px 2px ${boxShadow}`;

export const shadowRight = `1px 1px 7px 0 ${shadowColorLight};`;
export const shadowTop = `1px -2px 0 0 ${shadowColorLight};`;
export const shadowBottomRight = `1px 4px 3px 1px ${shadowColorLight};`;
export const shadowSmall = `0 2px 4px 2px ${shadowColorDark};`;
export const shadowSmallBottomRight = `-1px 0 3px 1px ${shadowColorBlack};`;
