import { config, library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faEdit,
  faExclamationTriangle,
  faFilter,
  faGripVertical,
  faImage,
  faInfoCircle,
  faMinusCircle,
  faPlayCircle,
  faSearch,
  faShare,
  faTimes,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as RawFontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const FontAwesomeIcon = styled(RawFontAwesomeIcon)`
  // Default size to 100% so they fill their container
  height: 1em;
  width: 1em;
`;

export function initializeFontAwesome() {
  config.autoAddCss = false;

  library.add(
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faEdit,
    faExclamationTriangle,
    faFilter,
    faGripVertical,
    faImage,
    faInfoCircle,
    faMinusCircle,
    faPlayCircle,
    faSearch,
    faTimes,
    faTrashAlt,
    faShare,
  );
}
