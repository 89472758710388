import { useOneSheet } from '@jaxsta/api/studer/one-sheet';
import type { PanelIds } from '@jaxsta/one-sheet';
import ErrorBoundary from '@jaxsta/ui/error-boundary/error-boundary';
import ErrorMessage from '@jaxsta/ui/errors/error-message';
import RecordLoader from '@jaxsta/ui/loader/record-loader';
import React, { FunctionComponent, Suspense } from 'react';

const OneSheet = React.lazy(
  () => import(/* webpackChunkName: "one-sheet-view" */ '../one-sheet'),
);

type WidgetSuspenseProps = {
  artist: string | null;
  panels: PanelIds[];
};

const OneSheetLoader = ({ artist, panels }: WidgetSuspenseProps) => {
  const data = useOneSheet(artist);

  return <OneSheet panels={panels} oneSheet={data!} />;
};

const WidgetSuspense: FunctionComponent<WidgetSuspenseProps> = (props) => {
  const renderErrorMessage = () => (
    <ErrorMessage
      message="There was an error loading the widget."
      tagline="Please try again later."
    />
  );

  return (
    <ErrorBoundary fallback={renderErrorMessage()}>
      <Suspense fallback={<RecordLoader inline />}>
        <OneSheetLoader {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default WidgetSuspense;
