import React, { PropsWithChildren, useContext, useMemo, useRef } from 'react';
import {
  Context as ResponsiveContext,
  MediaQueryAllQueryable,
} from 'react-responsive';
import { useMediaQueryClasses } from './media-query';
import useResizeObserver from './resize-observer-hook';

type ResponsiveContextProviderProps<TElement extends HTMLElement> = {
  children(ref: React.MutableRefObject<TElement | null>): React.ReactNode;
};

export function RawResponsiveContextProvider({
  children,
  ...props
}: PropsWithChildren<Partial<MediaQueryAllQueryable>>) {
  const isProgramaticallyResponsive = useMemo(() => true, []);

  return (
    <ResponsiveContext.Provider
      value={
        {
          ...props,
          isProgramaticallyResponsive,
        } as Partial<MediaQueryAllQueryable>
      }
    >
      {children}
    </ResponsiveContext.Provider>
  );
}

function ResponsiveContextProvider<TElement extends HTMLElement>({
  children,
}: ResponsiveContextProviderProps<TElement>) {
  const ref = useRef<TElement | null>(null);

  const [width] = useResizeObserver<TElement>(ref);

  // This needs to be memoized so this value doesn't cause
  // excessive re-renders.
  const isProgramaticallyResponsive = useMemo(() => true, []);

  return (
    <ResponsiveContext.Provider
      value={
        {
          width,
          isProgramaticallyResponsive,
        } as Partial<MediaQueryAllQueryable>
      }
    >
      {children(ref)}
    </ResponsiveContext.Provider>
  );
}

export type ResponsiveProps = {
  className?: string;
  isProgramaticallyResponsive?: boolean;
};

/**
 * Passes media props to a component.
 * WARNING: PLEASE PREFER media queries whenever possible.
 * Only use this in situations where media queries can't be used
 * ie. One-sheet embed widget
 * @param Component The component to have props passed to
 * @returns Component with props
 */
export function withResponsiveContext<TProps extends ResponsiveProps>(
  Component: React.ComponentType<TProps>,
) {
  return ({
    className,
    ...props
  }: Omit<TProps, keyof Omit<ResponsiveProps, 'className'>>) => {
    const mediaQueryClassNames = useMediaQueryClasses();

    const filteredMediaQueryClassNames = useMemo(
      () =>
        mediaQueryClassNames.filter(
          (o) => !className || !className.includes(o),
        ),
      [mediaQueryClassNames],
    );
    const contextData = useContext(ResponsiveContext) as
      | (Partial<MediaQueryAllQueryable> & {
          isProgramaticallyResponsive?: boolean;
        })
      | undefined;

    const builtClassName = useMemo(
      () =>
        [className, ...filteredMediaQueryClassNames]
          .filter((o) => !!o)
          .join(' '),
      [className, filteredMediaQueryClassNames],
    );

    return (
      <Component
        {...(props as TProps)}
        className={builtClassName}
        isProgramaticallyResponsive={contextData?.isProgramaticallyResponsive}
      />
    );
  };
}

export default ResponsiveContextProvider;
