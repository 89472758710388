import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { iconSize } from '@jaxsta/theme/layout';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '../assets/font-awesome';
import { ScreenReaderText } from '../screen-reader-text';

export type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: IconProp;
  srText?: string;
  color?: string;
};

const IconButton: FunctionComponent<IconButtonProps> = ({
  icon,
  srText,
  ...props
}) => (
  <ButtonStyled {...props}>
    {srText ? <ScreenReaderText>{srText}</ScreenReaderText> : null}
    <FontAwesomeIcon icon={icon} />
  </ButtonStyled>
);

const ButtonStyled = styled.button`
  position: relative;
  width: ${iconSize};
  height: ${iconSize};
  background: transparent;
  border: 0;
  cursor: pointer;

  > svg {
    width: 100%;
    height: 100%;
    color: ${(props) => props.color};
  }
`;

export default IconButton;
