import { mutate } from 'swr';
import { Entity, ResponseData } from '../../types/api';
import api from '../studer-api';
import { OneSheetData } from './types';

function buildURL(id: string) {
  return `one-sheet/${id}`;
}

export function getOneSheet(id: string) {
  return api
    .get<ResponseData<Entity<OneSheetData>>>({ path: buildURL(id) })
    .then((data) => data.data?.attributes);
}

export function patchOneSheet(
  id: string,
  payload: Partial<OneSheetData>,
  sessionToken?: string,
) {
  const path = buildURL(id);
  return api
    .patch<ResponseData<Entity<OneSheetData>>, Partial<OneSheetData>>({
      path,
      data: payload,
      sessionToken,
    })
    .then((resp) => {
      mutate(path); // Broadcast revalidation message on OneSheet
      return resp;
    });
}
