import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import useResizeObserver from '../responsive/resize-observer-hook';
import StyledComponentFrame, {
  StyledComponentFrameProps,
  useFrameContext,
} from './styled-component-frame';

type ContentHeightFrameProps = StyledComponentFrameProps & {};

const InternalRenderer: FunctionComponent<{
  heightChanged(height: number): void;
}> = ({ children, heightChanged }) => {
  const cont = useFrameContext();
  const ref = useRef<HTMLBodyElement | null>(cont.document.body);

  const [, height] = useResizeObserver(ref);

  useEffect(() => {
    heightChanged(height);
  }, [heightChanged, height]);

  return (children || null) as React.ReactElement;
};

const ContentHeightFrame: FunctionComponent<ContentHeightFrameProps> = ({
  children,
  ...rest
}) => {
  const [height, setHeight] = useState(0);

  return (
    <StyledComponentFrame
      {...rest}
      style={{
        ...rest.style,
        height: `${height}px`,
        transition: 'height 0.15s ease-out',
      }}
    >
      <InternalRenderer heightChanged={setHeight}>{children}</InternalRenderer>
    </StyledComponentFrame>
  );
};

export default ContentHeightFrame;
