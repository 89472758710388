export function setAlpha(color: string, alpha: number) {
  const match = /\((.+)\)\s*$/gm.exec(color);

  if (!match) {
    return color;
  }

  const parts: (string | number)[] = match[1].split(',').map((o) => o.trim());

  if (parts.length === 3) {
    parts.push(alpha);
  } else if (parts.length === 4) {
    parts[3] = alpha;
  }

  return `hsla(${parts.join(', ')})`;
}
