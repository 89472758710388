import {
  black as blackColor,
  grey10,
  grey50,
  grey70,
  grey80,
  grey90,
  primary as primaryColor,
  primaryDark,
  primaryLight,
  red,
  white,
} from '@jaxsta/theme/colors';
import { css } from 'styled-components';
import { hyperLinkStyle } from '../links/hyper-link';

interface ModeProperties {
  borderColor?: string;
  borderThickness?: number;
  borderRadius?: number;
  backgroundColor: string;
  color: string;
  hoverBackgroundColor: string;
  hoverBorderColor?: string;
  hoverColor?: string;
  activeBackgroundColor?: string;
  activeBorderColor?: string;
  disabledBackgroundColor?: string;
  disabledColor?: string;
  disabledBorderColor?: string;
}

function buttonCss(props: ModeProperties) {
  return css`
    background-color: ${props.backgroundColor};
    border: ${props.borderThickness || 1}px solid
      ${props.borderColor || props.backgroundColor};
    border-radius: ${props.borderRadius}px;

    color: ${props.color};
    position: relative;
    & svg {
      color: ${props.color};
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: ${props.hoverBackgroundColor};
        color: ${props.hoverColor || props.color};
        border-color: ${props.hoverBorderColor || props.hoverBackgroundColor};

        & svg {
          color: ${props.hoverColor || props.color};
        }
      }
    }

    &:active {
      transition: background 0s;
      background-color: ${props.activeBackgroundColor || props.backgroundColor};
      border-color: ${props.activeBorderColor ||
      props.borderColor ||
      props.activeBackgroundColor ||
      props.backgroundColor};
    }

    &[disabled],
    &:disabled {
      background-color: ${props.disabledBackgroundColor};
      color: ${props.disabledColor || props.color};
      border-color: ${props.disabledBorderColor ||
      props.borderColor ||
      props.disabledBackgroundColor ||
      props.backgroundColor};
      cursor: not-allowed;

      & svg {
        color: ${props.disabledColor || props.color};
      }

      &:hover {
        background: ${props.disabledBackgroundColor};
        color: ${props.disabledColor || props.color};
        border-color: ${props.disabledBorderColor ||
        props.borderColor ||
        props.disabledBackgroundColor ||
        props.backgroundColor};

        & svg {
          color: ${props.disabledColor || props.color};
        }
      }
    }
  `;
}

export const primary = buttonCss({
  backgroundColor: primaryColor,
  color: white,
  hoverBackgroundColor: primaryLight,
  hoverColor: white,
  activeBackgroundColor: primaryDark,
  disabledBackgroundColor: primaryLight,
  disabledBorderColor: primaryLight,
});

export const secondary = buttonCss({
  backgroundColor: white,
  borderColor: grey80,
  color: grey10,
  activeBackgroundColor: primaryDark,
  hoverBackgroundColor: primaryLight,
  hoverColor: white,
  disabledBackgroundColor: grey70,
  disabledBorderColor: blackColor,
});

export const tertiary = buttonCss({
  backgroundColor: grey90,
  color: blackColor,
  hoverBackgroundColor: grey90,
  hoverColor: primaryColor,
  disabledBackgroundColor: grey70,
});

export const selected = buttonCss({
  backgroundColor: white,
  borderColor: primaryColor,
  borderThickness: 2,
  color: primaryColor,
  hoverBackgroundColor: primaryLight,
  hoverBorderColor: primaryLight,
  hoverColor: white,
  activeBackgroundColor: primaryDark,
  activeBorderColor: primaryDark,
  disabledBackgroundColor: grey80,
  disabledBorderColor: grey70,
  disabledColor: blackColor,
});

export const selectedSolid = buttonCss({
  backgroundColor: primaryColor,
  borderThickness: 2,
  color: white,
  hoverBackgroundColor: primaryLight,
  activeBackgroundColor: primaryDark,
  disabledBackgroundColor: primaryLight,
  disabledBorderColor: primaryLight,
});

export const unselected = buttonCss({
  backgroundColor: white,
  borderColor: grey80,
  borderThickness: 2,
  color: grey10,
  hoverBackgroundColor: primaryLight,
  hoverColor: white,
  activeBackgroundColor: primaryDark,
  activeBorderColor: primaryDark,
  disabledBackgroundColor: grey80,
  disabledColor: grey50,
});

export const black = css``;

export const close = css``;

export const deleteButton = buttonCss({
  backgroundColor: white,
  color: red,
  borderThickness: 2,
  borderColor: red,
  hoverBackgroundColor: red,
  hoverColor: white,
  activeBackgroundColor: red,
  disabledBackgroundColor: grey80,
  disabledBorderColor: grey70,
  disabledColor: blackColor,
});

export const edit = css``;

export const link = css`
  ${hyperLinkStyle};
  background-color: inherit;
  color: inherit;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  margin: initial;
  outline: none;
  line-height: inherit;
  text-align: left;
`;

export const refresh = css``;

export const styleLess = css`
  background-color: inherit;
  color: inherit;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  margin: initial;
  outline: none;
  line-height: inherit;
`;

export const filter = buttonCss({
  backgroundColor: white,
  borderColor: primaryColor,
  borderThickness: 1,
  borderRadius: 5,
  color: primaryColor,
  activeBackgroundColor: primaryDark,
  activeBorderColor: primaryDark,
  hoverBackgroundColor: primaryLight,
  hoverColor: white,
  disabledBackgroundColor: grey90,
  disabledBorderColor: grey50,
  disabledColor: grey50,
});
