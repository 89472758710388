import { getWebsiteHost } from '@jaxsta/api/helpers/host';
import { black, grey85 } from '@jaxsta/theme/colors';
import { gutter } from '@jaxsta/theme/layout';
import { fontSize4, fontSize7 } from '@jaxsta/theme/typography';
import { LogoSvg } from '@jaxsta/ui/assets';
import { HyperLink } from '@jaxsta/ui/links/hyper-link';
import { media } from '@jaxsta/ui/responsive/media-query';
import { withResponsiveContext } from '@jaxsta/ui/responsive/responsive-context';
import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Link } from '../one-sheet.styles';

type FooterProps = {
  useDesktopStyle?: boolean;
  renderCustomContent?(): React.ReactNode;
};

function renderDefaultContent() {
  return (
    <>
      <TagLine>
        The information on this One Sheet has come from official sources via
      </TagLine>
      <LogoLink href={getWebsiteHost()} target="_blank" undecorated>
        <LogoSvg />
      </LogoLink>
      <TagLineCta>
        <p>
          <strong>Want your own official One Sheet? Then check out </strong>{' '}
          <BottomLink
            href={`${getWebsiteHost()}/news-article/45635`}
            target="_blank"
            rel="noreferrer"
          >
            How (And Why) To Set Up Your Jaxsta One Sheet!
          </BottomLink>
        </p>
      </TagLineCta>
    </>
  );
}

const Footer: FunctionComponent<FooterProps> = ({
  renderCustomContent = renderDefaultContent,
  ...props
}) => <TagLineWrapper {...props}>{renderCustomContent()}</TagLineWrapper>;

const wrapperDesktopStyle = css`
  background-color: ${grey85};
  justify-content: center;
`;

export const TagLineWrapper = withResponsiveContext(styled.div<{
  useDesktopStyle?: boolean;
}>`
  display: flex;
  flex-flow: row wrap;
  gap: ${gutter};
  justify-content: space-between;
  padding: ${gutter};
  width: 100%;
  box-sizing: border-box;

  ${media('desktop', wrapperDesktopStyle)};
  ${(props) => props.useDesktopStyle && wrapperDesktopStyle}
`);

export const tagLineBaseStyle = () => css`
  font-size: ${fontSize7};
  margin: 0;

  ${media(
    'desktop',
    css`
      align-self: center;
    `,
  )};
`;

export const TagLine = withResponsiveContext(styled.p<{
  useDesktopStyle?: boolean;
}>`
  ${tagLineBaseStyle};
  ${(props) =>
    !props.useDesktopStyle &&
    css`
      width: 60%;
    `};

  ${media(
    'desktop',
    css`
      width: auto;
    `,
  )};
`);

export const TagLineCta = withResponsiveContext(styled.div`
  ${tagLineBaseStyle};
  text-align: left;
  width: 100%;

  > p {
    margin: 0;
  }

  ${media(
    'desktop',
    css`
      text-align: center;
    `,
  )};
`);

export const LogoLink = withResponsiveContext(styled(HyperLink)`
  max-width: 86px;
  width: 35%;
  margin-right: ${gutter};

  > svg {
    height: ${fontSize4};
  }

  ${media(
    'desktop',
    css`
      width: auto;
      margin-right: 0;
    `,
  )};

  &:hover {
    color: ${black};
  }
`);

const BottomLink = styled(Link)`
  font-size: ${fontSize7};
`;

export default Footer;
