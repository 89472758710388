import { devices, DeviceTypes } from '@jaxsta/theme';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { css, FlattenSimpleInterpolation } from 'styled-components';

export function media(
  device: DeviceTypes,
  styling: FlattenSimpleInterpolation,
) {
  return (...args: any[]) => {
    const { isProgramaticallyResponsive } = args[0] as {
      isProgramaticallyResponsive?: boolean;
    };

    return css`
      ${isProgramaticallyResponsive
        ? `&.${device}`
        : `@media ${devices[device]}`} {
        ${styling};
      }
    `;
  };
}

export function useMedia(device: DeviceTypes) {
  return useMediaQuery({ query: devices[device] });
}

export function useMediaQueryClasses(): DeviceTypes[] {
  const landscape = useMediaQuery({
    query: devices.landscape,
  });
  const tablet = useMediaQuery({ query: devices.tablet });
  const desktop = useMediaQuery({
    query: devices.desktop,
  });
  const widescreen = useMediaQuery({
    query: devices.widescreen,
  });

  const queries: Partial<Record<DeviceTypes, boolean>> = useMemo(
    () => ({
      landscape,
      tablet,
      desktop,
      widescreen,
    }),
    [landscape, tablet, desktop],
  );

  return useMemo(
    () =>
      Object.keys(queries).filter(
        (key) => !!queries[key as DeviceTypes],
      ) as DeviceTypes[],
    [queries],
  );
}
