import { gutter, padPortrait } from '@jaxsta/theme/layout';
import { css } from 'styled-components';
import { media } from '../responsive/media-query';

export const sectionContainerStyle = css`
  max-width: 1366px;
  width: 100%;
`;

export const makeRow = css`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const padRow = css`
  padding-left: ${padPortrait};
  padding-right: ${padPortrait};
`;

export const grid2col = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${gutter};

  ${media(
    'tablet',
    css`
      grid-template-columns: 1fr 1fr;
    `,
  )}
`;

export const grid3col = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${gutter};

  ${media(
    'tablet',
    css`
      grid-template-columns: 1fr 1fr;
    `,
  )}

  ${media(
    'desktop',
    css`
      grid-template-columns: 1fr 1fr 1fr;
    `,
  )}
`;

export const gridRowFullWidth = css`
  grid-column: 1 / -1;
`;
