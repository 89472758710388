import { fontSize7, lightWeight } from '@jaxsta/theme/typography';
import React, { FunctionComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '../assets/font-awesome';
import { media } from '../responsive/media-query';

export type TooltipSides = 'top' | 'right' | 'bottom' | 'left';

export type TooltipProps = {
  id: string;
  text: string;
  side?: TooltipSides;
  className?: string;
};

const Tooltip: FunctionComponent<TooltipProps> = ({
  id,
  text,
  side,
  className,
  ...props
}) => (
  <TooltipWrapper className={className}>
    <IconWrapper data-tip data-for={id}>
      <StyledIcon icon="info-circle" size="lg" {...props} />
    </IconWrapper>
    <StyledTooltip place={side} id={id} effect="solid">
      {text}
    </StyledTooltip>
  </TooltipWrapper>
);

export const TooltipWrapper = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  display: flex;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  height: auto;

  ${media(
    'tablet',
    css`
      font-size: 17px;
    `,
  )}
`;

// Gross need for !important to override react-tooltip styling (as documented by them)
// https://github.com/wwayne/react-tooltip
const StyledTooltip = styled(ReactTooltip)`
  font-weight: ${lightWeight} !important;
  font-size: ${fontSize7} !important;
  padding: 4px 8px !important;
  max-width: 200px;
`;

export default Tooltip;
