import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '../assets/font-awesome';
import BouncingLoader from '../loader/bouncing-loader';
import * as buttonModes from './button-modes';
import * as buttonSizes from './button-sizes';
import * as buttonWidths from './button-widths';

export type WidthType = keyof typeof buttonWidths;
export type ButtonSizes = keyof typeof buttonSizes;
export type ButtonType = keyof typeof buttonModes;
export type IconPlacement = 'left' | 'right';

type BaseButtonProps = {
  widthType?: WidthType;
  size?: ButtonSizes;
  mode?: ButtonType;
  tab?: boolean;
  icon?: IconProp;
  iconPlacement?: IconPlacement;
  loading?: boolean;
};

type ButtonProps = React.ButtonHTMLAttributes<unknown> & BaseButtonProps;

const Button: FunctionComponent<ButtonProps> = ({
  mode = 'primary',
  size = 'small',
  widthType = 'auto',
  icon,
  iconPlacement = 'left',
  loading,
  children,
  ...props
}) => {
  const renderIcon = (side: IconPlacement) => {
    if (!icon || iconPlacement !== side) {
      return null;
    }

    return <FontAwesomeIcon icon={icon} />;
  };

  return (
    <ButtonStyled
      type="button"
      {...props}
      mode={mode}
      size={size}
      iconPlacement={iconPlacement}
      widthType={widthType}
    >
      {loading ? (
        <BouncingLoader />
      ) : (
        <>
          {renderIcon('left')}
          {children}
          {renderIcon('right')}
        </>
      )}
    </ButtonStyled>
  );
};

const ButtonStyled = styled.button<BaseButtonProps>`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  outline: none;
  height: auto;
  border-radius: 30px;
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    vertical-align: baseline;

    ${(props) =>
      props.iconPlacement === 'left'
        ? css`
            margin-right: 5px;
          `
        : css`
            margin-left: 5px;
          `};
  }

  ${(props) => props.mode && buttonModes[props.mode]}
  ${(props) => props.widthType && buttonWidths[props.widthType]};
  ${(props) => props.size && buttonSizes[props.size]};

  ${(props) =>
    props.tab &&
    css`
      border-radius: 5px 5px 0 0;
    `};
`;

export default Button;
