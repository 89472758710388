import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

type RecordLoaderProps = {
  inline?: boolean;
};

const RecordLoader: FunctionComponent<RecordLoaderProps> = (props) => (
  <Canvas data-test-id="loading" {...props}>
    <div className="jacket {{if inline jacket-inline}}">
      <div className="part front" />
      <div className="part back" />
      <div className="part disc">
        <div className="label">
          <div className="dot" />
        </div>
      </div>
    </div>
  </Canvas>
);

const Canvas = styled.div<RecordLoaderProps>`
  position: relative;
  z-index: 1;

  .jacket {
    width: 30px;
    height: 30px;
    position: relative;
    margin: -60px auto 40px;
    top: 50%;
    perspective: 800px;
    animation: fade-in 0.3s 1 0s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
    ${(props) =>
      props.inline &&
      css`
        margin: 0 auto;
      `}
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .part {
    border-radius: 3px;
    width: 30px;
    height: 30px;
    position: absolute;
    transform-style: preserve-3d;
  }

  .front {
    transform-origin: left center;
    z-index: 2;
    animation: front 2s infinite 0s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    background: #209f85;
  }

  @keyframes front {
    0% {
      transform: translateX(00%) translateY(00%) rotateY(0deg);
    }

    25% {
      transform: translateX(-10%) translateY(00%) rotateY(-180deg);
    }

    40% {
      transform: translateX(-10%) translateY(00%) rotateY(-180deg);
      opacity: 1;
    }

    46% {
      transform: translateX(-10%) translateY(00%) rotateY(-180deg);
      opacity: 0;
    }

    68% {
      transform: translateX(100%) translateY(00%) rotateY(-180deg);
      opacity: 0;
    }

    100% {
      transform: translateX(100%) translateY(00%) rotateY(-180deg);
      opacity: 0;
    }
  }

  .disc {
    z-index: 0;
    background: #333;
    margin-top: 1px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    animation: disc 2s infinite 0s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .label {
    z-index: 1;
    background: none;
    border: thin solid #555;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dot {
    z-index: 1;
    background: #555;
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }
  @keyframes disc {
    15% {
      transform: translateX(00%);
    }

    30% {
      transform: translateX(110%);
    }

    46% {
      transform: translateX(110%);
    }

    68% {
      transform: translateX(40%);
    }

    100% {
      transform: translateX(0%);
    }
  }

  .back {
    transform-origin: left center;
    background: #ddd;
    z-index: 1;
    animation: back 2s infinite 0s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  @keyframes back {
    24% {
      z-index: 2;
      transform: translateX(0%) translateY(00%) rotateY(0deg);
      background: #ddd;
    }

    46% {
      z-index: 2;
      transform: translateX(-10%) translateY(00%) rotateY(-180deg);
      background: #ddd;
    }

    68% {
      z-index: 2;
      transform: translateX(100%) translateY(00%) rotateY(-180deg);
      background: #ddd;
    }

    80% {
      z-index: 2;
      transform: translateX(100%) translateY(00%) rotateY(-180deg);
      background: #ddd;
    }

    100% {
      z-index: 2;
      transform: translateX(100%) translateY(00%) rotateY(-180deg);
      background: #209f85;
    }
  }
`;

export default RecordLoader;
