import { css } from 'styled-components';

// Speeds
export const fastSpeed = '100ms';
export const defaultSpeed = '300ms'; // Default because most animations should aim to finish after a blink
export const slowSpeed = '600ms';
export const snailSpeed = '800ms';

// Cubic Beziers
const whiplashBezier = 'cubic-bezier(0.125, 0.565, 0, 1)';

// Animations
export const animDefaultStyle = `${defaultSpeed} ${whiplashBezier}`;
export const animFastStyle = `${fastSpeed} ${whiplashBezier}`;
export const animMedStyle = `${slowSpeed} ${whiplashBezier}`;
export const animSlowStyle = `${snailSpeed} ${whiplashBezier}`;
export const animTransformFastStyle = `transform ${fastSpeed} ease`;

export const fadeIn = () => css`
  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  animation: 200ms linear fadein;
  transition: all ${animFastStyle};
`;
