import { white } from '@jaxsta/theme/colors';
import React, { FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';

type BouncingLoaderProps = {
  color?: string;
  size?: number;
};

const BouncingLoader: FunctionComponent<BouncingLoaderProps> = (props) => (
  <Loader {...props}>
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </Loader>
);

const skBounceDelay = keyframes`
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
`;

const Loader = styled.div<BouncingLoaderProps>`
  display: inline-flex;
  padding: 5px;

  & > div {
    margin-right: 10px;
    width: ${(props) => props.size || 13}px;
    height: ${(props) => props.size || 13}px;
    background-color: ${(props) => props.color || white};
    border-radius: 100%;
    display: inline-block;
    animation: ${skBounceDelay} 1.4s infinite ease-in-out both;

    &:last-child {
      margin-right: 0;
    }
  }

  & .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  & .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
`;

export default BouncingLoader;
