import { getWebsiteHost } from '@jaxsta/api/helpers/host';
import { black, grey70, white } from '@jaxsta/theme/colors';
import { borderRadiusLg, borderRadiusSm } from '@jaxsta/theme/decorations';
import { gutter } from '@jaxsta/theme/layout';
import { fontSize5, fontSize6, regularWeight } from '@jaxsta/theme/typography';
import { LogoSvg, LogoWhiteSvg } from '@jaxsta/ui/assets';
import { sectionContainerStyle } from '@jaxsta/ui/grid/grid';
import { withResponsiveContext } from '@jaxsta/ui/responsive/responsive-context';
import { H5 } from '@jaxsta/ui/typography';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Footer, { LogoLink, TagLine } from '../footer/footer';

type WidgetFrameProps = {
  innerRef?: React.MutableRefObject<HTMLDivElement | null>;
};

const WidgetFrame: FunctionComponent<WidgetFrameProps> = ({
  children,
  innerRef,
  ...props
}) => {
  const renderFooterContent = () => (
    <>
      <TagLine useDesktopStyle>Powered by</TagLine>
      <LocalLogoLink href={getWebsiteHost()} target="_blank" undecorated>
        <LogoFooter />
      </LocalLogoLink>
    </>
  );

  return (
    <WidgetContainer ref={innerRef} {...props}>
      <WidgetHeader>
        <HeaderLogoLink href={getWebsiteHost()} target="_blank">
          <Logo />
        </HeaderLogoLink>
        <HeaderContent>One Sheet</HeaderContent>
      </WidgetHeader>
      <WidgetContent>{children}</WidgetContent>
      <StyledFooter renderCustomContent={renderFooterContent} useDesktopStyle />
    </WidgetContainer>
  );
};

const WidgetContainer = styled.div`
  ${sectionContainerStyle};
  border-radius: ${borderRadiusLg};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: static;
  visibility: visible;
  padding: 0;
  border: 3px solid ${black};
  margin-top: 0;
  margin-bottom: 0;
  background-color: ${white};
`;

const WidgetHeader = styled.div`
  background-color: ${black};
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${gutter};
`;

const HeaderLogoLink = styled.a`
  padding: 0;
  margin: 0;
  margin-top: 1px;
  height: ${fontSize5};
`;
const LogoFooter = styled(LogoSvg)``;

const Logo = styled(LogoWhiteSvg)`
  height: ${fontSize5};
`;

const HeaderContent = styled(H5)`
  margin: 0;
  font-weight: ${regularWeight};
  margin-left: 8px;
  border-left: 1px solid ${grey70};
  color: ${white};
  font-size: ${fontSize6};
  padding: 0 6px;
`;

const WidgetContent = styled.div`
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LocalLogoLink = withResponsiveContext(styled(LogoLink)`
  max-width: 50px;
  margin-top: 6px;
`);

const StyledFooter = styled(Footer)`
  border-bottom-left-radius: ${borderRadiusSm};
  border-bottom-right-radius: ${borderRadiusSm};
  padding-top: 8px;
  align-items: center;
`;

export default WidgetFrame;
