export type DeviceTypes =
  | 'portrait'
  | 'landscape'
  | 'tablet'
  | 'desktop'
  | 'widescreen'
  | 'superscreen';

export const sizes: Record<DeviceTypes, string> = {
  portrait: '0',
  landscape: '480px',
  tablet: '769px',
  desktop: '1025px',
  widescreen: '1401px',
  superscreen: '1801px',
};

export const devices: Record<DeviceTypes, string> = {
  portrait: 'all',
  landscape: `all and (min-width: ${sizes.landscape})`,
  tablet: `all and (min-width: ${sizes.tablet})`,
  desktop: `all and (min-width: ${sizes.desktop})`,
  widescreen: `all and (min-width: ${sizes.widescreen})`,
  superscreen: `all and (min-width: ${sizes.superscreen})`,
};

export const onRetina = '(min-resolution: 192dpi)';
