import {
  animFastStyle,
  animMedStyle,
  fastSpeed,
} from '@jaxsta/theme/animation';
import {
  grey85,
  grey90,
  grey95,
  primary,
  primaryLight,
  white,
} from '@jaxsta/theme/colors';
import { borderRadiusLg } from '@jaxsta/theme/decorations';
import {
  gutter,
  gutterPortrait,
  gutterSuperscreen,
  padSuperscreen,
} from '@jaxsta/theme/layout';
import {
  boldWeight,
  fontSize4,
  fontSize5,
  fontSize6,
  fontSize8,
  regularWeight,
} from '@jaxsta/theme/typography';
import Button from '@jaxsta/ui/buttons/button';
import IconButton from '@jaxsta/ui/buttons/icon-button';
import { padRow, sectionContainerStyle } from '@jaxsta/ui/grid/grid';
import { HyperLink } from '@jaxsta/ui/links/hyper-link';
import { media } from '@jaxsta/ui/responsive/media-query';
import {
  ResponsiveProps,
  withResponsiveContext,
} from '@jaxsta/ui/responsive/responsive-context';
import { Tooltip } from '@jaxsta/ui/tooltip';
import { H3, h4Style } from '@jaxsta/ui/typography';
import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  background: white;
  border-bottom: 1px solid ${grey95};
  padding-bottom: calc(${gutter} * 2);
`;

export const Header = styled.div`
  ${sectionContainerStyle};
  display: block;
  text-align: center;
`;

export const Title = styled(H3)`
  ${padRow};
  margin: 0;
`;

export const SubTitle = styled.div`
  ${padRow};
  font-size: ${fontSize6};
  margin: 0;
`;

export const ReorderableSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`;

export const OneSheetSectionBase = withResponsiveContext(styled.div<{
  inline?: boolean;
  centerAligned?: boolean;
  order?: number;
}>`
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 14px;

  ${(props) =>
    media(
      'desktop',
      css`
        ${props.inline &&
        css`
          display: flex;
          flex-flow: row nowrap;
          padding-top: calc(${gutter} * 2);
        `};

        ${props.centerAligned &&
        css`
          align-items: center;
        `};
      `,
    )};

  ${(props) =>
    props.order &&
    css`
      order: ${props.order};
    `};
`);

export const OneSheetSection = withResponsiveContext(
  styled(OneSheetSectionBase)<ResponsiveProps>`
    padding-left: ${gutterPortrait};
    padding-right: ${gutterPortrait};

    ${media(
      'desktop',
      css`
        padding-left: ${padSuperscreen};
        padding-right: ${padSuperscreen};
        padding-bottom: ${gutterSuperscreen};
        border-bottom: 2px solid ${grey85};
      `,
    )};
  `,
);

export const EditOneSheetSection = withResponsiveContext(
  styled(OneSheetSectionBase)<ResponsiveProps>`
    padding: 0;
  `,
);

export const SectionHeader = withResponsiveContext(styled.p<{
  inline?: boolean;
}>`
  ${h4Style};
  border-bottom: 1px solid ${grey85};
  font-weight: ${regularWeight};

  ${media(
    'desktop',
    css`
      border-bottom: none;
    `,
  )};

  ${(props) =>
    props.inline &&
    media(
      'desktop',
      css`
        margin: 0 calc(${gutter} * 3) 0 0;
      `,
    )}
`);

export const EditSectionHeaderWrapper = withResponsiveContext(styled.div<{
  reordering: boolean;
}>`
  display: flex;
  background-color: transparent;
  border-bottom: 1px solid ${grey85};
  padding: 0 0 4px;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  transition: all ${animFastStyle};

  ${(props) =>
    props.reordering &&
    css`
      background-color: ${grey90};
      border-bottom: 0px solid ${grey85};
      border-radius: ${borderRadiusLg};
      padding: 4px 8px;
    `};
`);

export const EditSectionTitle = withResponsiveContext(styled(SectionHeader)<{
  inline?: boolean;
}>`
  font-size: ${fontSize6};
  font-weight: ${boldWeight};
  margin: 0;
  border: none;

  ${media(
    'tablet',
    css`
      font-size: ${fontSize5};
    `,
  )};

  ${media(
    'desktop',
    css`
      font-size: ${fontSize4};
    `,
  )};
`);

export const EditSectionContentWrapper = styled.div`
  padding: ${gutter} 0 0;
`;

export const Link = styled(HyperLink)`
  color: ${primary};
  transition: 0.2s;
  text-decoration: none;
  font-size: ${fontSize6};

  &:hover,
  &:focus {
    color: ${primaryLight};
  }
`;

export const Links = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const EditIconButton = styled(IconButton).attrs({ icon: 'edit' })`
  color: ${primary};
  border: 0 solid transparent;
  border-radius: 100%;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -11px;
  right: -11px;
  transition: border ${fastSpeed}, background-color ${animMedStyle};

  svg.svg-inline--fa {
    height: 20px;
    width: 20px;
    position: relative;
    right: -2px;
  }

  &:hover,
  &:focus {
    border: 4px solid ${primary};
  }

  &:active {
    background-color: ${primary};
    color: ${white};
  }
`;

export const EditButton = styled(Button)`
  display: block;
  margin: ${gutter} 0 0;
`;

export const EditEmptyMessage = styled.p`
  margin: 0 0 ${gutter};
  font-size: ${fontSize8};
  font-weight: ${regularWeight};
`;

export const StyledTooltip = styled(Tooltip)`
  margin-left: ${gutter};
`;

export const StyledModalHeader = withResponsiveContext(styled.div`
  font-size: ${fontSize5};
  font-weight: ${boldWeight};

  ${media(
    'desktop',
    css`
      font-size: ${fontSize4};
    `,
  )};
`);
