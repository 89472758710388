import React, { FunctionComponent } from 'react';
import Frame, { FrameContextConsumer, useFrame } from 'react-frame-component';
import styled, {
  createGlobalStyle,
  StyleSheetManager,
} from 'styled-components';

export type StyledComponentFrameProps = {
  style?: React.CSSProperties;
};

const StyledComponentFrame: FunctionComponent<StyledComponentFrameProps> = ({
  style = {},
  children,
  ...rest
}) => (
  <StyledFrame style={style} {...rest}>
    <FrameContextConsumer>
      {(frameContext: any) => (
        <StyleSheetManager target={frameContext.document.head}>
          <>
            <Global />
            {children}
          </>
        </StyleSheetManager>
      )}
    </FrameContextConsumer>
  </StyledFrame>
);

const Global = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
`;

const StyledFrame = styled(Frame)`
  display: block;
  overflow: hidden;
  border: 0;
  width: 100%;
`;

export function useFrameContext() {
  return useFrame();
}

export default StyledComponentFrame;
