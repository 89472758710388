export const padPortrait = '12px';
export const padLandscape = '12px';
export const padTablet = '30px';
export const padDesktop = '30px';
export const padWidescreen = '30px';
export const padSuperscreen = '46px';

export const gutterPortrait = '10px';
export const gutterLandscape = '12px';
export const gutterTablet = '14px';
export const gutterDesktop = '16px';
export const gutterWidescreen = '18px';
export const gutterSuperscreen = '20px';

export const gutter = '8px';

export const boostPortrait = '0%';
export const boostLandscape = '5%';
export const boostTablet = '10%';
export const boostDesktop = '15%';
export const boostWidescreen = '15%';
export const boostSuperscreen = '15%';

export const rowUnit = '50px';

export const imageSizeMobile = '75px';
export const imageSizeDesktop = '85px';
export const imageSizeLgTileMobile = '100px';
export const imageSizeLgTileDesktop = '140px';
export const imageCardSizeDesktop = '118px';

export const imageSizeListDesktop = '75px';
export const imageSizeListTablet = '50px';

export const iconSize = '30px';
export const iconSizeSmall = '15px';

export const scrollbarWidth = '7px';
