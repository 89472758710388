import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type ScreenReaderTextProps = {};

const ScreenReaderText: FunctionComponent<ScreenReaderTextProps> = ({
  children,
}) => <ScreenReaderTextWrap>{children}</ScreenReaderTextWrap>;

/* Screen reader only style */
const ScreenReaderTextWrap = styled.span`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  position: absolute;
  padding: 0;
  overflow: hidden;
  width: 1px;
`;

export default ScreenReaderText;
