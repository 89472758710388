import {
  fontSize6,
  fontSize7,
  fontSize8,
  fontSize9,
  regularWeight,
} from '@jaxsta/theme/typography';
import { css } from 'styled-components';
import { smallStyle } from '../typography';

export const large = css`
  padding: 10px 25px;
  font-size: ${fontSize6};
  font-weight: ${regularWeight};
`;

export const medium = css`
  font-size: ${fontSize7};
  line-height: normal;
  font-weight: ${regularWeight};
  padding: 8px 20px;
`;

export const small = css`
  font-size: ${fontSize8};
  line-height: normal;
  font-weight: ${regularWeight};
  padding: 6px 15px;
`;

export const xSmall = css`
  ${smallStyle};
  font-size: ${fontSize9};
  padding: 4px 10px;
`;
