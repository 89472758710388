import { css } from 'styled-components';

export const full = css`
  width: 100%;
  display: block;

  .loader {
    display: block;
    padding: 0;
  }
`;

export const half = css`
  max-width: 200px;
  padding: 10px 25px;
`;

export const auto = css`
  width: auto;
  padding: 5px 25px;
`;
