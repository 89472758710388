import {
  baseLineHeight,
  boldWeight,
  fontSize1,
  fontSize2,
  fontSize3,
  fontSize4,
  fontSize5,
  fontSize6,
  fontSize7,
  fontSize8,
  fontSize9,
  lightWeight,
  regularWeight,
  superLightWeight,
} from '@jaxsta/theme/typography';
import styled, { css } from 'styled-components';

export const h1Style = () => css`
  font-size: ${fontSize1};
  font-weight: ${boldWeight};
  line-height: calc(${fontSize1} * 1.5);
  margin: calc(${fontSize1} / 6) 0 calc(${fontSize1} / 6) 0;
`;

export const H1 = styled.h1`
  ${h1Style};
`;

export const h2Style = () => css`
  font-size: ${fontSize2};
  font-weight: ${boldWeight};
  line-height: calc(${fontSize2} * 1.4);
  margin: calc(${fontSize2} / 6) 0 calc(${fontSize2} / 6) 0;
`;

export const H2 = styled.h2`
  ${h2Style};
`;

export const h3Style = () => css`
  font-size: ${fontSize3};
  font-weight: ${boldWeight};
  line-height: 1.3;
  margin: calc(${fontSize3} / 1.5) 0 calc(${fontSize3} / 1.5) 0;
`;

export const H3 = styled.h3`
  ${h3Style};
`;

export const h4Style = () => css`
  font-size: ${fontSize4};
  font-weight: ${boldWeight};
  margin: calc(${fontSize4} / 1.5) 0 calc(${fontSize4} / 1.5) 0;
`;

export const H4 = styled.h4`
  ${h4Style};
`;

export const h5Style = () => css`
  font-size: ${fontSize5};
  font-weight: ${boldWeight};
  line-height: 1.3;
  margin: calc(${fontSize5} / 1.5) 0 calc(${fontSize5} / 1.5) 0;
`;

export const H5 = styled.h5`
  ${h5Style};
`;

export const bodyTextStyle = () => css`
  font-size: ${fontSize5};
  font-weight: ${boldWeight};
  margin: 0 0 calc(${fontSize5} / 6) 0;
`;

export const BodyText = styled.div`
  ${bodyTextStyle};
`;

export const boldStyle = () => css`
  ${bodyTextStyle};
  font-weight: ${regularWeight};
`;

export const BoldText = styled.span`
  ${boldStyle};
`;

export const regularStyle = () => css`
  ${bodyTextStyle};
  font-weight: ${regularWeight};
`;

export const RegularText = styled.span`
  ${regularStyle};
`;

export const lightStyle = () => css`
  ${bodyTextStyle};
  font-weight: ${lightWeight};
`;

export const LightText = styled.span`
  ${lightStyle};
`;

export const superLightStyle = () => css`
  ${bodyTextStyle};
  font-weight: ${superLightWeight};
`;

export const SuperLightText = styled.span`
  ${superLightStyle};
`;

export const subtitleStyle = () => css`
  ${bodyTextStyle};
  ${regularStyle};
  font-size: ${fontSize6};
  margin: 0 0 calc(${fontSize7} / 6) 0;
`;

export const Subtitle = styled.div`
  ${subtitleStyle};
`;

export const smallStyle = () => css`
  font-size: ${fontSize8};
  font-weight: ${lightWeight};
`;

export const Small = styled.span`
  ${bodyTextStyle};
  ${smallStyle};
`;

export const xSmallStyle = () => css`
  ${bodyTextStyle};
  font-size: ${fontSize9};
  font-weight: ${lightWeight};
`;

export const XSmall = styled.span`
  ${xSmallStyle};
`;

export const jumboStyle = () => css`
  font-size: 50px;
  font-weight: ${boldWeight};
  line-height: 1.1;
`;

export const Jumbo = styled.span`
  ${jumboStyle};
`;

export const ClampedText = styled.div`
  /* It's assumed this is used in a element with max-width set. */
  overflow: hidden;
  display: block;
`;

export const Clamp1Line = styled(ClampedText)`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Clamp2Lines = styled(ClampedText)`
  ${subtitleStyle};
  line-height: ${baseLineHeight};
  max-height: calc(${baseLineHeight} * 28px);
  position: relative;

  &::after {
    content: '';
    text-align: left;
    position: absolute;
    top: 18px;
    right: 0;
    width: 25%;
    height: calc(${baseLineHeight} * 12);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 70%
    );
    z-index: 0;
  }
`;

export const Clamp3Lines = styled(Clamp2Lines)`
  max-height: calc(${baseLineHeight} * 36px);

  &::after {
    top: calc(${baseLineHeight} * 24px);
  }
`;
