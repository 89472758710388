import { ResizeObserver, ResizeObserverEntry } from '@juggle/resize-observer';
import {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react';

export default function useResizeObserver<TElement extends HTMLElement>(
  ref: MutableRefObject<TElement | null>,
  callback?: (entry: DOMRectReadOnly) => void,
) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);

      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback],
  );

  const sendHandleResize = (el: HTMLElement) => {
    handleResize([
      {
        contentRect: {
          width: el.clientWidth,
          height: el.clientHeight,
        } as DOMRectReadOnly,
      } as ResizeObserverEntry,
    ]);
  };

  const fallbackResize = useCallback(() => {
    const { documentElement } = window.self.document;
    sendHandleResize(documentElement);
  }, []);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    try {
      let RO: ResizeObserver | null = new ResizeObserver((entries) =>
        handleResize(entries),
      );
      RO.observe(ref.current);

      return () => {
        RO?.disconnect();
        RO = null;
      };
    } catch (ex) {
      // If there is no self, then just
      // set the size of the current ref.
      // We also need to set it initially
      // even if there is a self
      sendHandleResize(ref.current);

      // if the parent is an iframe, which we know because
      // the code above errored out, we can do the
      // resizing based on the iframe itself
      if (window.self) {
        window.self.addEventListener('resize', fallbackResize);

        return () => {
          window.self.removeEventListener('resize', fallbackResize);
        };
      }

      return () => {
        // fallback
      };
    }
  }, [ref]);

  return [width, height];
}
